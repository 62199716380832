/*
 * Name        : DFA Nav Module
 * Version     : 0.4.4
 * Author      : Deep Fried Advertising
 * Author URL  : https://deepfriedads.com
 * Description : Adds click event listeners to `.Nav a`
*/

import { $, $html, $navButton } from '../_var';

const $nav = $('.Nav__button');
const $subnav = $('.menu-item-has-children');


/* eslint-disable func-names */
export default () => {
	$nav.on('click', function (event) {
		// Store/cache the link as a local variable
		const thisLink = $(this);

		// Handle: a[href="#"]
		if ('#' === thisLink.attr('href')) {
			event.preventDefault();
		}

		// Handle: a.js-open-nav
		if (thisLink.is($navButton)) {
			event.preventDefault();
			$html.toggleClass('Nav--is-open');
		}

		if ($subnav.hasClass('Subnav--is-open')) {
			$subnav.removeClass('Subnav--is-open');
		}
	});

	$subnav.on('click', function () {
		$(this).toggleClass('Subnav--is-open');
	});
};
/* eslint-enable func-names */
