/*
 * Name        : DFA Sliders
 * Version     : 0.2.0
 * Author      : Deep Fried Advertising
 * Author URL  : https://deepfriedads.com
 * Description : Setup Sliders
*/

import { $ } from './_var';

export default () => {
	const $bxslider = $('.bxslider');

	if (0 < $bxslider.length) {
		$bxslider.bxSlider({
			controls: false,
			pager: false,
			touchEnabled: false,
			auto: true,
			speed: 800,
			pause: 6500,
			adaptiveHeight: true,
			onSliderLoad() {
				$bxslider[0].children[1].classList.add('active-slide');
			},
			onSlideBefore(oldIndex, newIndex) {
				const nextSlide = oldIndex[0];
				const oldSlide = oldIndex.prevObject[newIndex];
				nextSlide.classList.add('active-slide');
				oldSlide.classList.remove('active-slide');
			},
		});
	}
};
