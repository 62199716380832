/*
 * Name        : DFA Back to Top Module
 * Version     : 0.5.0
 * Author      : Deep Fried Advertising
 * Author URL  : https://deepfriedads.com
 * Description : Adds a click event listener to `.back-to-top`
*/

import { $, $htmlAndBody } from '../_var';

const $backToTop = $('.back-to-top');

function callback() {
	let fromTop = window.pageYOffset;

	if( fromTop >= 800)
	{
		$backToTop.addClass('show-it');
	}else
	{
		$backToTop.removeClass('show-it');
	}
}




// For custom speed, adjust timing parameter
export default (timing) => {
	window.addEventListener('scroll', callback );

	const time = 'undefined' !== typeof timing ? timing : 250;
	$backToTop.click((event) => {
		event.preventDefault();
		$htmlAndBody.animate({ scrollTop: 0 }, time);
	});
};
