import { fetch as fetchPolyfill } from 'whatwg-fetch';
import './deep-fried-modules/_polyfills';
import setupNav from './deep-fried-modules/_nav';
import setupBackToTop from './deep-fried-modules/_back_to_top';
import smoothScroll from './_smooth-scroll';
import setupRetinaAssets from './deep-fried-modules/_retina';
import setupACFGoogleMaps from './deep-fried-modules/_acf_google_maps';
import setupSlider from './_slider';
// import setupSelect2 from './_select2';
import setupContentSlider from './_contentSlider';
import setupFilter from './_filter';
import drawer from './_drawer';
import setupModal from './_modal';

jQuery(document).ready(() => {
	setupNav();
	setupBackToTop();
	setupRetinaAssets();
	setupACFGoogleMaps();
	setupSlider();
	// setupSelect2();
	setupContentSlider();
	setupFilter();
	smoothScroll();
	drawer();
	setupModal();
});
