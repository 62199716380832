/**
 * Builds Modal.
 *
 * @author Deep Fried Advertising {@internal Ben, Geoff}
 * @version 0.2.0
 * @since 0.2.0
 * @param object obj
 * @return Element html
 */


const Modal = document.querySelector('.Modal');
const body = document.querySelector('body');

function makeModalHTML() {
	const modalHTML = '<div class="Modal__loading Container"><button aria-label="Close Modal" class="Modal__close"><i class="fas fa-times"></i></button><div class="Modal__content"></div>';
	Modal.innerHTML = modalHTML;

	return Modal;
}

function showModal() {
	body.classList.add('Modal--is-open');
}

function closeModal() {
	body.classList.remove('Modal--is-open');
}

function maybeCloseModal(event) {
	if (Modal === event.target) {
		closeModal();
	}
}

function closeModalSetup() {
	const closeModalButton = Modal.querySelector('.Modal__close');
	closeModalButton.addEventListener('click', closeModal);
	Modal.addEventListener('click', maybeCloseModal);
}

function placeModalContent(html) {
	const ModalContent = Modal.querySelector('.Modal__content');
	ModalContent.innerHTML = '';
	ModalContent.insertAdjacentHTML('afterbegin', html);
}

export default function () {
	const button = document.querySelectorAll('[data-target-modal]');

	const Modals = document.querySelectorAll('.Modal');
	if (1 < Modals.length) {
		Array.from(Modals)
			.filter((elem, i) => i > 0 )
			.map(extraModals => extraModals.outerHTML = '');
	}

	if (Modal) {
		makeModalHTML();
		closeModalSetup();
		Array.from(button).forEach(elem => {
			elem.addEventListener('click', function(event) {
				event.preventDefault();
				const content = document.querySelector(`[data-modal='${this.dataset.targetModal}']`);
				placeModalContent(content.innerHTML);
				showModal();
			});
		});
	}
}
