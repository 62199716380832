/*
 * Name        : DFA ACF Google Maps
 * Version     : 0.7.1
 * Author      : Deep Fried Advertising
 * Author URL  : https://deepfriedads.com
 * Description : Builds Google Map from ACF fields
*/

import { $, $window } from '../_var';
import waitForFinalEvent from './_final_event';
import '../lib/markerclusterer';
import MAP_STYLES from '../_google-map-styles';

/* eslint-disable func-names, no-plusplus */

function addACFGoogleMapMarker($marker, m, markerOptions) {
	const markerHTML = $marker.html();
	const latlng = new google.maps.LatLng($marker.attr('data-lat'), $marker.attr('data-lng'));
	const defaultMarkerOptions = {
		position: latlng,
		map: m,
	};

	const markerSettings = $.extend({}, defaultMarkerOptions, markerOptions);

	const marker = new google.maps.Marker(markerSettings);
	m.markers.push(marker);

	if (markerHTML) {
		google.maps.event.addListener(marker, 'click', () => {
			m.info_window.setContent(markerHTML);
			m.info_window.open(m, marker);
		});
	}
}

function centerACFGoogleMap(m) {
	const bounds = new google.maps.LatLngBounds();

	m.markers.forEach((marker) => {
		const latlng = new google.maps.LatLng(marker.position.lat(), marker.position.lng());
		bounds.extend(latlng);
	});

	if (1 === m.markers.length) {
		m.setCenter(bounds.getCenter());
	} else {
		m.fitBounds(bounds);

		$window.on('resize', () => {
			waitForFinalEvent(m.fitBounds(bounds), 100, 'DFA_fitBounds');
		});
	}
}

function initMarkerClusterer(obj) {
	// Bail if not defined.
	if ('undefined' === typeof MarkerClusterer || 'undefined' === typeof obj.map || 'undefined' === typeof obj.markers) {
		return;
	}

	const markerClustererStyles = [];
	const markerClustererStylesSizes = ('undefined' === typeof obj.sizes) ? [53, 56, 66, 78, 90] : obj.sizes;

	const cssHack = 'dfa-dont-remove:('; // Check out limitations of ClusterIcon.prototype.createCss() in markerclusterer.js to see why we're doing this.

	for (let i = 0, size; i < markerClustererStylesSizes.length; i++) {
		size = markerClustererStylesSizes[i];
		markerClustererStyles.push({
			height: size,
			textColor: '#ffffff',
			url: `${DFA.PATHS.stylesheet_directory_uri}/images/icons/markers/m${i + 1}@2x.png); background-size: cover;${cssHack}`,
			width: size,
		});
	}

	const markerClusterer = new MarkerClusterer(obj.map, obj.markers, {
		styles: markerClustererStyles,
	});
}

function makeACFGoogleMap($el) {
	// Get the markers for this map
	const $markers = $el.find('.dfa-acf-map-marker');
	const mapSettings = {
		mapTypeControl: $el.data('maptypecontrol') || false,
		mapTypeId: $el.data('maptype') || google.maps.MapTypeId.ROADMAP,
		scrollwheel: $el.data('scrollwheel') || false,
		zoom: $el.data('zoom') || 12,
	};

	// Set defaults
	const defaults = {
		center: new google.maps.LatLng(0, 0),
		jQuery_element: $el,
		mapTypeControl: mapSettings.mapTypeControl,
		mapTypeId: mapSettings.mapTypeId,
		scrollwheel: mapSettings.scrollwheel,
		styles: MAP_STYLES,
		zoom: mapSettings.zoom,
	};
	// Overload settings
	const settings = defaults;

	// Create map
	const map = new google.maps.Map($el[0], settings);
	map.markers = [];
	map.info_window = new google.maps.InfoWindow();

	// Add markers
	if (0 < $markers.length) {
		$markers.each(function () {
			const $element = $(this);
			addACFGoogleMapMarker($element, map, $element.data('marker-options'));
		});

		initMarkerClusterer({ map, markers: map.markers });
	}

	// Center map
	centerACFGoogleMap(map);

	// Return
	return map;
}

export default () => {
	const acf = {};
	acf.google_maps = {};
	acf.google_maps.new_map = null;
	acf.google_maps.$maps = $('.dfa--map-canvas');

	// Only proceed if we have google object and some maps
	if ('undefined' !== typeof google && 0 < acf.google_maps.$maps.length) {
		acf.google_maps.$maps.each(function (index) {
			acf.google_maps.$maps[index] = makeACFGoogleMap($(this));
		});
	}
};
/* eslint-enable func-names, no-plusplus */
