/*
 * Name        : DFA Filter
 * Version     : 0.1.0
 * Author      : Deep Fried Advertising
 * Author URL  : https://deepfriedads.com
 * Description : Setup Filter
*/

// import { $ } from './_var';
function toogleQuestions() {
	const questions = document.querySelectorAll('.Layout__question');

	if (0 < questions.length) {
		Array.from(questions)
			.forEach(question => question.addEventListener( 'click', showAnswer ));
	}

	function showAnswer() {
		const questionWrap = this.offsetParent;
		questionWrap.classList.toggle('active');
	};
}


export default () => {
	toogleQuestions();
};
