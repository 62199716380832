/*
 * Name        : DFA Smooth Scoll to a link
 * Author      : Deep Fried Advertising
 * Author URL  : https://deepfriedads.com
 * Description : Adds a click event listener to a link with '#';
*/

import { $, $htmlAndBody } from './_var';

// For custom speed, adjust timing parameter
export default () => {
	$("section a").on('click', function(event) {
	  if (this.hash !== "") {
		event.preventDefault();
		var hash = $(this).attr("href");
		var destination = $(hash).offset().top;

		$('html, body').animate( {
		  	scrollTop: destination - 100
		}, 600 );
	  }
	});
};
