/*
* Name        : DFA Retina-ready Assets
* Version     : 0.4.0
* Author      : Deep Fried Advertising
* Author URL  : https://deepfriedads.com
* Description : Swaps in retina assets when detected.
*/

import { $ } from '../_var';

const isRetina = () => 1 < window.devicePixelRatio;

/* eslint-disable func-names */
const useRetina = ($selector) => {
	$selector.each(function () {
		const $el = $(this);
		const retinaSrc = $el.data('retina-src');
		if (retinaSrc) {
			$el.attr('src', retinaSrc);
		}
	});
};
/* eslint-enable func-names */

export default () => {
	if (true === isRetina()) {
		const $allImgs = $('img');
		useRetina($allImgs);
	}
};
