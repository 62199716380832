/*
 * Name        : DFA Content Slider
 * Version     : 0.1.0
 * Author      : Deep Fried Advertising
 * Author URL  : https://deepfriedads.com
 * Description : Setup Content Slider
*/

import { $ } from './_var';

function toggleClassOnCycle(container, items) {
	if (container) {
		let counter = 1;

		items.eq(0).addClass('is-active is-visible'); // Start with first item active

		setInterval(() => {
			const thisItem = items.eq(counter);

			if (items.hasClass('is-active is-visible')) {
				items.removeClass('is-visible');
				setTimeout(() => {
					items.removeClass('is-active');
					thisItem.addClass('is-active');
				}, 1000);
			}

			setTimeout(() => {
				thisItem.addClass('is-visible');
			}, 1300);

			counter += 1;

			if (counter === items.length) {
				counter = 0; // Reset counter after last slide
			}
		}, 6000);
	}
}

export default function () {
	const $contentSlider = $('.ContentSlider');
	const $contentSlides = $('.ContentSlide');
	if (0 < $contentSlider.length) {
		toggleClassOnCycle($contentSlider, $contentSlides);
	}
}
