/*
 * Name        : DFA Filter
 * Version     : 0.1.0
 * Author      : Deep Fried Advertising
 * Author URL  : https://deepfriedads.com
 * Description : Setup Filter
*/

function filterCards() {
	const thisSelector = this;
	const selectedValue = thisSelector[thisSelector.selectedIndex].value;
	const cards = document.querySelectorAll('[data-school-level]');

	Array.from(cards)
		.forEach((card) => {
			const str = card.dataset.schoolLevel;
			const thisCardValues = str.split(',');

			card.classList.remove('is-active');

			if ('0' === selectedValue) {
				card.classList.add('is-active');
			}
			if (thisCardValues.includes(selectedValue)) {
				card.classList.add('is-active');
			}
		});
}

function initFilter() {
	const select = document.querySelector('select[name="school-levels"]');
	if (select) {
		select.addEventListener('change', filterCards);
	}
}

export default () => {
	initFilter();
};
